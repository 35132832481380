import { MENU_DATA_DETAILS } from './menu-constant-data';

// Priveleges - 1 VP of Operations, 2 Plant Operator, 3 Admin
export const MENU_DATA = {
  1: [
    MENU_DATA_DETAILS.PLANT_VIEW,
    MENU_DATA_DETAILS.ASSET_MONITORING,
    MENU_DATA_DETAILS.PREDICTIVE_MAINTENANCE,
    MENU_DATA_DETAILS.ASSET_MANAGEMENT,
    MENU_DATA_DETAILS.ASSET_KPI,
    MENU_DATA_DETAILS.WEARABLE_ASSETS,
    MENU_DATA_DETAILS.MANUFACTURING_COPILOT,
  ],
  2: [
    MENU_DATA_DETAILS.PLANT_VIEW,
    MENU_DATA_DETAILS.ASSET_MONITORING,
    MENU_DATA_DETAILS.PREDICTIVE_MAINTENANCE,
    MENU_DATA_DETAILS.ASSET_MANAGEMENT,
    MENU_DATA_DETAILS.ASSET_KPI,
    MENU_DATA_DETAILS.WEARABLE_ASSETS,
    MENU_DATA_DETAILS.MANUFACTURING_COPILOT,
  ],
  3: [
    MENU_DATA_DETAILS.PLANT_VIEW,
    MENU_DATA_DETAILS.ASSET_MONITORING,
    MENU_DATA_DETAILS.PREDICTIVE_MAINTENANCE,
    MENU_DATA_DETAILS.ASSET_MANAGEMENT,
    MENU_DATA_DETAILS.ASSET_KPI,
    MENU_DATA_DETAILS.WEARABLE_ASSETS,
    MENU_DATA_DETAILS.MANUFACTURING_COPILOT,
  ],
};
