import { environment } from "@env";

export const API_SUB_URLS = {
    coreAPI: 'iotsphereapi/',
    securedApi: 'securedApi/',
    deviceManagementApi: 'devicemanagementapi/',
    mxdApi:'mxdapi/',
    simulatorApi:'Simulator/'
}

export function getCoreAPIBaseUrl(): string {
    return environment.apiUrl + API_SUB_URLS.coreAPI;
}

function getSecuredAPIUrl(): string {
    return environment.apiUrl + API_SUB_URLS.coreAPI + API_SUB_URLS.securedApi;
}

export function getDeviceManagementUrl(): string {
    return environment.apiUrl + API_SUB_URLS.deviceManagementApi;
}

export function getDeviceLocationAPIUrl():string {
    return 'https://euwdsrg02rafapp02.azurewebsites.net/api/';
}

export function getSimulatorAPIUrl():String{
    return environment.apiUrl + API_SUB_URLS.mxdApi + API_SUB_URLS.simulatorApi;
}

export const API_URLS = {
    keyVaultKeys: getSecuredAPIUrl() + 'keyVaultKeys',
    addAssetEndPoint: getSecuredAPIUrl() + "addOrUpdateAsset",
    retrieveAssetInfoEndPoint: getSecuredAPIUrl() + "fetchAssetParams",
    updateAssetInfoEndPoint: getSecuredAPIUrl() + "updateAssetInfo",
    deleteAssetEndPoint: getSecuredAPIUrl() + "deleteAsset",
    IoTStartCommandEndPoint: getSecuredAPIUrl() + "opcEdgeCommandServiceStartCall",
    IoTStopCommandEndPoint: getSecuredAPIUrl() + "opcEdgeCommandServiceStopCall",
    allCriticalEventsEndPoint: getSecuredAPIUrl() + "getPolarDelightEvents",
    onlineOfflineStatus: getSecuredAPIUrl() + "getAssetsStatus",
    fetchAllVehicles: getSecuredAPIUrl() + 'fetchAllVehicles',
    fetchDistinctAssets: getSecuredAPIUrl() + "fetchDistinctAssets",
    startCsvSimulatorEndPoint: getSecuredAPIUrl() + "startCsvSimulator",
    stopCsvSimulatorEndPoint: getSecuredAPIUrl() + "stopCsvSimulator",
    isSimulatorStatusEndPoint: getSecuredAPIUrl() + "isSimulatorsRunning",
    deviceDetailsEndPoint: getDeviceManagementUrl() + 'deviceDetailsEndPoint',
    getDeviceTwinData: getDeviceManagementUrl() + "GetDeviceTwinData",
    UpdateDeviceTwinData: getDeviceManagementUrl() + 'UpdateDeviceTwinData',
    directMethodCallEndPoint: getDeviceManagementUrl() + "CallDirectMethod",
    getConnectionHistoryForDevice: getDeviceManagementUrl() + 'GetConnectionHistoryForDevice',
    getAllDevicesLocaltion: getDeviceManagementUrl() + 'GetDeviceLatLongList',
    createEnrolment: getDeviceManagementUrl() + 'CreateEnrolment',
    bulkEnrolment: getDeviceManagementUrl() + 'BulkEnrolment',
    getDeviceTypes: getDeviceManagementUrl() + 'GetDeviceTypes',
    getExistingGroupEnrolmentIds: getDeviceManagementUrl() + 'GetExistingGroupEnrolmentIds',
    blockOrUnBlockIndividualDevice: getDeviceManagementUrl() + 'BlockOrUnBlockIndividualDevice',
    uploadSensorMetadata: getDeviceManagementUrl() + 'UploadSensorMetadata',
    getDeviceDetailList: getDeviceManagementUrl() + 'GetDeviceDetailList',
    blockGroupEnrolledDeviceWithCertificate: getDeviceManagementUrl() + 'BlockGroupEnrolledDeviceWithCertificate',
    decommissionDevice: getDeviceManagementUrl() + 'DecommissionDevice',
    createEmptyGroupEnrollmentWithCertificate: getDeviceManagementUrl() + 'CreateEmptyGroupEnrollmentWithCertificate',
    getOrphanDeviceCount: getDeviceManagementUrl() + 'GetOrphanDeviceCount',
    getOrphanDeviceList: getDeviceManagementUrl() + 'GetOrphanDeviceList',
    reuploadDecommisionedDevice: getDeviceManagementUrl() + 'ReuploadDecommisionedDevice',
    bulkUpdateOrphanDevice: getDeviceManagementUrl() + 'BulkUpdateOrphanDevice',
    bulkUpdateOrphanDeviceFromCSV: getDeviceManagementUrl() + 'BulkUpdateOrphanDeviceFromCSV',
    getTotalTelemetryCountForAllDevices: getDeviceManagementUrl() + 'GetTotalTelemetryCountForAllDevices',
    getTotalTelemetryCount: getDeviceManagementUrl() + 'GetTotalTelemetryCount',
    getSensorDetails: getDeviceManagementUrl() + 'GetSensorDetails',


    // Firmware
    getAllFirmware: getSecuredAPIUrl() + 'allFirmwares',
    downloadSpecificFirmware: getCoreAPIBaseUrl() + 'download/',
    deleteSpecificFirmware: getCoreAPIBaseUrl() + 'remove/',
    saveFirmware: getSecuredAPIUrl() + 'save',

    //get device location API
    getDeviceLocation:getDeviceLocationAPIUrl()+'GetADXSonitorData',

    //get asset simulator API
    deleteAsset: getSimulatorAPIUrl()+ 'Delete/AssetDetails',
    getAssetDetailsById:getSimulatorAPIUrl()+'GetAssetDetailById',
    editAssetDetails:getSimulatorAPIUrl()+'EditAsset'
}