import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule} from '@angular/material/menu';


import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { THIRD_PARTY_CONFIGURATIONS } from '../configurations/third-party-config';
import { SafePipe } from './pipe/safe.pipe';
import { DynamicIframeLoaderComponent } from '../common/components/dynamic-iframe-loader/dynamic-iframe-loader.component';

const materialModules = [
  MatToolbarModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatCardModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatDialogModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatIconModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatMenuModule,
];

const reusableComponents = [DynamicIframeLoaderComponent];

@NgModule({
  declarations: [...reusableComponents, SafePipe],
  imports: [
    CommonModule,
    ...materialModules,
    ToastrModule.forRoot(THIRD_PARTY_CONFIGURATIONS.TOASTR_CONFIG),
    ModalModule.forRoot(),
  ],
  exports: [
    ...materialModules,
    ...reusableComponents,
    ToastrModule,
    ModalModule,
  ],
  providers: [ToastrService, BsModalService, DatePipe, SafePipe],
})
export class SharedModule {}
