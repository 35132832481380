import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
    selector: 'app-dynamic-iframe-loader',
    templateUrl: './dynamic-iframe-loader.component.html',
    styleUrls: ['./dynamic-iframe-loader.component.scss']
})
export class DynamicIframeLoaderComponent implements OnInit {
    dynamicSource: string;
    isDigitalTwinUrl: boolean;
    displayDashboardIcon:boolean=false;
    constructor(private router: Router, private utilsService: UtilsService) {
        const menuData = this.utilsService.menuData;
        const currentRoute = this.router.url;
        const dynamicData = this.getDynamicUrl(menuData, currentRoute);
        this.isDigitalTwinUrl = dynamicData.isDigitalTwinUrl;
        this.dynamicSource = dynamicData.dynamicUrl;
    }

    ngOnInit(): void {
        if(this.router.url.indexOf('floor-1') > -1){
            window.open(this.dynamicSource, '_blank', 'toolbar=0,location=0,menubar=0');
        }
        this.showDashboardIcon();
    }

    showDashboardIcon(){
        setTimeout(() => {
           this.displayDashboardIcon=true;
          }, 2000);
    }

    getDynamicUrl(menuData, currentRoute): any {
        let dynamicUrl = '';
        let isDigitalTwinUrl = '';
        for (const menu of menuData) {
            if (menu.routerLink === currentRoute) {
                dynamicUrl = menu.dynamicUrl;
                isDigitalTwinUrl = menu.isDigitalTwinUrl;
                break;
            } else {
                if (menu.children) {
                    for (const subMenu of menu.children) {
                        if (subMenu.routerLink === currentRoute) {
                            dynamicUrl = subMenu.dynamicUrl;
                            isDigitalTwinUrl = subMenu.isDigitalTwinUrl;
                            break;
                        } else {
                            if (subMenu.children) {
                                for (const child of subMenu.children) {
                                    if (child.routerLink === currentRoute) {
                                        dynamicUrl = child.dynamicUrl;
                                        isDigitalTwinUrl = child.isDigitalTwinUrl;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { dynamicUrl, isDigitalTwinUrl };
    }

}
