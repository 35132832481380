<div id="iframe-loader" *ngIf="dynamicSource"
    [ngClass]="isDigitalTwinUrl? 'ey-ml-46 ey-digital-twin': 'ey-mt-40 ey-h-100'">
    <!-- dashboard icon added explicitly to hide menu icon of Grafana dashboard start -->
    <div class="dashboard-icon-container" *ngIf="displayDashboardIcon">
        <img class="dashboard-icon" src="assets/images/grafana/dashboard.svg" alt="dashboard_icon" />
    </div>
    <!-- dashboard icon added explicitly to hide menu icon of Grafana dashboard end -->
    <iframe width=100% height=100% [src]="dynamicSource | safe: 'resourceUrl'" frameborder="0"
        allowFullScreen="true"></iframe>
    <!-- Empty div added explicitly to hide toggle icon of Grafana dashboard to hide sign in option start -->
    <div class="empty-div-container" *ngIf="displayDashboardIcon"></div>
    <!-- Empty div added explicitly to hide toggle icon of Grafana dashboard to hide sign in option end -->
</div>