<mat-toolbar class="header" color="primary">
    <div class="header-parent">
    <div class="ey-vc">
        <img src="assets/dist/images/EY_Logo_Beam_RGB.png" alt="EY Logo" class="ey-logo" />
        <p class="heading-class title-content">
            {{title}}
        </p>
    </div>
    <ng-content></ng-content>
    <div class="logoutDiv">
        <div class="dropdown header-menu-tab">
            <button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu()" class = "user-button">
                <mat-icon class="user-button-icon">person</mat-icon>
                {{ loggedInUser }}
                <mat-icon class="user-button-icon">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <div class="menu-bg">
                <span>
                    <button mat-menu-item *ngFor="let role of userRoles" class="menu-items">
                        <a (click)="userDefinedTabs(role)"><mat-icon class="user-button-icon">account_box</mat-icon>{{ role }}</a>
                    </button>
                    <button mat-menu-item [disabled]="isDisabled" class="menu-items">
                        <a><mat-icon class="user-button-icon">settings</mat-icon>Settings</a>
                    </button>
                    <button mat-menu-item class="menu-items">
                        <a (click)="logout()"><mat-icon class="user-button-icon">logout</mat-icon>Logout</a>
                    </button>
                </span>
            </div>
            </mat-menu>
        </div>
    </div>
</div>
</mat-toolbar>