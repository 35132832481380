import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './features/home/components/header/header.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HomeComponent } from './features/home/components/home/home.component';
import { NavComponent } from './common/components/nav/nav.component';
import { MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [AppComponent, HomeComponent, HeaderComponent, NavComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    MatMenuModule
  ],
  providers: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
