import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { KeyVaultService } from './services/key-vault.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private authService: AuthService,
    private keyVaultSvc: KeyVaultService
  ) {
  }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: any) => {
        this.authService.token = result.payload.idToken;
        const redirectUri = localStorage.getItem('redirectUrlAfterLogin');
        if (redirectUri) {
          localStorage.removeItem('redirectUrlAfterLogin');
          this.router.navigate([redirectUri]);
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.LOGOUT_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: any) => {
        this.authService.clear();
        this.router.navigate(['/login']);
      });
  }

  getKeyVault(): void {
    this.keyVaultSvc.getAllKeySecrets().subscribe((data) => {
      const keys = data;
      localStorage.setItem('tsiEnvironmentId', keys[0]['value']);
      localStorage.setItem(
        'azureMapSubscriptionKey',
        keys[2]['value']
      );
      localStorage.setItem('azureDigitalTwinUrl', keys[3]['value']);
      localStorage.setItem('redirectUri', (keys[4]['value']));
      localStorage.setItem('evalertlogicappendpoint', (keys[5]['value']));
      localStorage.setItem('pdalertlogicappendpoint', (keys[6]['value']));
    });
  }

  ngOnDestroy(): void {
    this.msalService.logout();
  }

}
