export const MENU_DATA_DETAILS = {
    PLANT_VIEW: {
        id: 1,
        label: 'Plant View',
        icon: 'apartment',
        children: [
            {
                id: 1,
                label: 'Summary',
                icon: 'view_quilt',
                imgClass: 'assets/images/asset-kpi/cnc3.png',
                routerLink: '/home/plant-view/summary',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/JdSIsnUSk/home-page?orgId=1',
                children: [],
            },
            // {   
            //     id: 2,
            //     label: 'Quality Metrics',
            //     icon: 'insights',
            //     imgClass: 'assets/images/asset-kpi/cnc3.png',
            //     routerLink: '/home/plant-view/quality-metrics',
            //     dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/BILfMPF4z/quality-and-financial-metrics-dashboard?orgId=1',
            //     children: [],
            // },
            {
                id: 3,
                label: 'Downtime',
                icon: 'trending_down',
                imgClass: 'assets/images/asset-kpi/cnc1.png',
                routerLink: '/home/plant-view/downtime',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/ik8f8RnVd2/plant-level-downtime?orgId=1',
                children: [],
            },
            {
                id: 4,
                label: 'Floor 1',
                icon: 'insights',
                isDigitalTwinUrl: true,
                imgClass: 'assets/images/asset-kpi/cnc3.png',
                routerLink: '/home/plant-view/floor-1',
                dynamicUrl: 'https://explorer.digitaltwins.azure.net/3DScenes/?sceneId=30ba3e63deb14d5f8992a00787bcffbc&selectedElementIds=1dfdddd4c20d434e8cf4620fcda8631a&selectedLayerIds=scene-layer-dropdown-unlayered-behaviors&mode=Viewer&adtUrl=https%3A%2F%2Feuwdsrg03radt01.api.weu.digitaltwins.azure.net&adtResourceId=%2Fsubscriptions%2F21be855a-14e1-4de5-b670-01ca21a7a46b%2FresourceGroups%2FEUWDSRG03RRSG01%2Fproviders%2FMicrosoft.DigitalTwins%2FdigitalTwinsInstances%2FEUWDSRG03RADT01&storageUrl=https%3A%2F%2Feuwdsrg03rsta03.blob.core.windows.net%2F3dscenes&tid=5b973f99-77df-4beb-b27d-aa0c70b8482c',
                children: [],
            },            
        ]        
    },
    ASSET_MONITORING: {
        id: 2,
        label: 'Asset Monitoring',
        icon: 'precision_manufacturing',
        children: [
            {
                id: 1,
                label: 'Vitals',
                icon: 'web',
                routerLink: '/home/asset-monitoring/vitals',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/ugZKJR74za/benchmill-asset-vital-dashboard?orgId=1',
                children: [],
            },
            {
                id: 2,
                label: 'KPI',
                icon: 'summarize',
                routerLink: '/home/asset-monitoring/kpi',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/gVnrcoJVz/mxd-asset-summary?orgId=1',
                children: [],
            }
        ]
    },
    PREDICTIVE_MAINTENANCE: {
        id: 3,
        label: 'Predictive Maintenance',
        icon: 'engineering',
        children: [
            {
                id: 1,
                label: 'Anomaly Detection',
                icon: 'troubleshoot',                
                routerLink: '/home/anomaly-detection',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/0HjTsXBVz/anomaly-prediction-maintenance-dashboard?orgId=1',
                children: [],
            },
            {
                id: 2,
                label: 'Asset Failure Prediction',
                icon: 'online_prediction',
                imgClass: 'assets/images/asset-kpi/cnc1.png',
                routerLink: '/home/asset-failure-prediction',
                dynamicUrl: 'https://euwdsrg03rwap14.azurewebsites.net/d/n-0YMRa4k/predictive-maintenance-dashboard?orgId=1',
                children: [],
            }
        ]
    },
    ASSET_MANAGEMENT: {
        id: 4,
        label: 'Asset Management',
        icon: 'developer_board',
        children: [
            {
                id: 1,
                label: 'Asset-Creation',
                icon: 'view_quilt',
                routerLink: '/home/device-management/asset-creation',
                children: [],
            },
            {
                id: 2,
                label: 'Asset-Dashboard',
                icon: 'web',
                routerLink: '/home/device-management/asset-dashboard',
                children: [],
            }
        ]
    },
    ASSET_KPI:{
        id: 5,
        label: 'Asset KPI Monitoring',
        icon: 'domain',
        routerLink: '/home/asset-kpi',
        children: [],
    },
    WEARABLE_ASSETS:{
        id: 6,
        label: 'Wearable Assets',
        icon: 'important_devices',
        children: [
            {
                id: 1,
                label: 'Wearable Transfer Analysis',
                icon: 'view_quilt',
                routerLink: '/home/wearable-assets/wearable-transfer-analysis',
                children: [],
            },
            {
                id: 2,
                label: 'Downgrade Transfer Analysis',
                icon: 'web',
                routerLink: '/home/wearable-assets/downgrade-transfer-analysis',
                children: [],
            },
            {
                id: 3,
                label: 'Floor Map',
                icon: 'web',
                routerLink: '/home/wearable-assets/floor-map',
                children: [],
            },
            {
                id: 4,
                label: 'Indoor Map',
                icon: 'web',
                routerLink: '/home/wearable-assets/indoor-map',
                children: [],
            }
        ]        
    },
    MANUFACTURING_COPILOT:{
        id: 12,
        label: 'Manufacturing Copilot',
        icon: 'code',
        routerLink: '/home/manufacturing-copilot',
        children: [],
    },
};
