import { Inject, Injectable } from '@angular/core';

import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenKey: string = 'msal.idToken';
  constructor(private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) { }

  get token(): string {
    return localStorage.getItem(this.tokenKey);
  }

  set token(value: string) {
    localStorage.setItem(this.tokenKey, value);
  }

  clear(): void {
    localStorage.clear();
  }

  loginFn(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }
}
