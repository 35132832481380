import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedInUser: string;
  userRoles = ['Admin', 'VP of Operations', 'Plant Operator'];
  defaultuserRole: any;

  title: string = 'Manufacturing Data Platform';

  isDisabled = true;

  constructor(private authService: MsalService, private router: Router) {}

  ngOnInit(): void {
    this.defaultuserRole = localStorage.getItem('role') ?? this.userRoles[0];
    this.loggedInUser = this.authService.instance
      .getActiveAccount()
      .name.split('.')
      .join(' ');
  }

  home() {
    this.router.navigate(['/home/device-management']);
  }
  logout() {
    sessionStorage.removeItem('tsqExpressions');
    localStorage.removeItem('role');
    this.router.navigate(['/logout']);
  }
  userDefinedTabs(role = 'VP of Operations') {
    localStorage.setItem('role', role);
    location.reload();
  }
}
